import React from 'react';

const InputElement = (props) => (
    <input
        type={props.type}
        id={props.id}
        name={props.name}
        className={props.className}
        onClick={props.onClick}
        value={props.value}
    >
    </input>
);

export default InputElement;