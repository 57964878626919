import React from 'react';


import InputElement from '../InputElement/InputElement.js'
import LabelElement from '../LabelElement/LabelElement.js'
import TextareaElement from '../TextareaElement/TextareaElement.js'

class FormElement extends React.Component {
    state = {
        activeElement: '',
    };

    toggleActiveFormElement = () => {
        this.setState({
          activeElement: 'active'
        });
    }

    render() {
        let labelClasses = [];
        if(this.state.activeElement) {
            labelClasses.push('active');
          }
        return (
            <form id="fs-frm" name="simple-contact-form" accept-charset="utf-8" action="https://formspree.io/hello@jessecodes.me" method="post">
                <div className="row">
                    <div className="col-md-6">
                        <div className="md-form mb-0">
                            <InputElement
                                type="text"
                                id="full-name"
                                name="name"
                                className="form-control"
                                onClick={this.toggleActiveFormElement}
                            />
                            <LabelElement
                                for="name"
                                className={labelClasses}
                                value="Your name"
                                onClick={this.toggleActiveFormElement}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="md-form mb-0">
                            <InputElement
                                type="text"
                                id="email-address"
                                name="sender's email"
                                className="form-control"
                                onClick={this.toggleActiveFormElement}
                            />
                            <LabelElement
                                for="email"
                                className={labelClasses}
                                value="Your email"
                                onClick={this.toggleActiveFormElement}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="md-form mb-0">
                            <InputElement
                                type="text"
                                id="subject"
                                name="subject"
                                className="form-control"
                                onClick={this.toggleActiveFormElement}
                            />
                            <LabelElement
                                for="subject"
                                className={labelClasses}
                                value="Subject"
                                onClick={this.toggleActiveFormElement}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/* Grid column */}
                    <div className="col-md-12">
                        <div className="md-form">
                            <TextareaElement 
                                type="text"
                                id="message"
                                name="message"
                                className="form-control md-textarea"
                                rows="2"
                                onClick={this.toggleActiveFormElement}
                            />
                            <LabelElement
                                for="message"
                                className={labelClasses}
                                value="Your message"
                                onClick={this.toggleActiveFormElement}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="md-form text-center">
                            <InputElement
                                type="submit"
                                value="Submit"
                                className="btn btn-primary mb-2"
                            />
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default FormElement;