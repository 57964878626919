import React from 'react';
import ContactForm from '../ContactForm/ContactForm.js'

function App() {
  return (
    <div className="App">
      <div className="container">
        <ContactForm></ContactForm>
      </div>
    </div>
  );
}

export default App;
