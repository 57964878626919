import React from 'react';

const LabelElement = (props) => (
    <label
        for={props.for}
        className={props.className}
        onClick={props.onClick}
    >
        {props.value}
    </label>
);

export default LabelElement;