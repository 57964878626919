import React from 'react';

const TextareaElement = (props) => (
    <textarea
        type={props.type}
        id={props.id}
        name={props.name}
        className={props.className}
        rows={props.rows}
        onClick={props.onClick}    
    >
    </textarea>
);

export default TextareaElement;