import React from 'react';

import FormElement from '../FormElement/FormElement.js'

class ContactForm extends React.Component {

  render() {
    return (
      <section className="mb-4">
          <h2 className="h1-responsive font-weight-bold text-center my-4">Contact Jesse</h2>
          <div className="row">
              <div className="col-md-12 mb-md-0 mb-5">
                  <FormElement></FormElement>
              </div>
          </div>
      </section>
    );
  }
}

export default ContactForm;